import React, {useState} from 'react';
import {Navigate} from "react-router-dom";
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {registerUser} from "../../utils/redux/store/authSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {FaRegSquareFull} from "react-icons/fa6";
import {FiCheck} from "react-icons/fi";
import loginslide1 from "../../assets/loginslide1.png";
import loginslide2 from "../../assets/loginslide2.jpeg";
import loginslide3 from "../../assets/loginslide3.jpeg";
import loginslide4 from "../../assets/loginslide4.jpeg";
import SimpleImageSlider from "react-simple-image-slider";

import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";


export const Register = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const [reqData, setReqData] = useState({email: '', password: '', isOrganization: true, organizationName: '', fullName: ''})
    const [orgShow, setOrgShow] = useState(true)
    const registerSuccess: boolean = useAppSelector(state => state.auth.registerSuccess);
    const [showPassword, setShowPassword] = useState(false);
    const [acceptTerm, setAcceptTerm] = useState(false);
    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleLoginFacebook = () => {
        toast.warning("under development")
    }
    const handleLoginLinkedin = () => {
        toast.warning("under development")
    }
    const handleLoginGoogle = () => {
        toast.warning("under development")
    }
    const handleRegister = () => {
        if (!reqData.email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(reqData.email)) {
            toast.warning("Please enter a valid email address.");
            return
        }
        dispatch(registerUser(reqData))
    }
    if (registerSuccess) {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                }}
                replace
            />
        )
    }
    return (
        <div className="row mx-0 h-100">
            <div className="col-12 col-lg-6 mt-5 px-0 px-lg-5">
                <div className='row mx-0 justify-content-center mt-5'>
                    <div className='col-12'>
                        <Typography className='text-center' variant='h2' component='h1'>
                            Create an Account
                        </Typography>
                    </div>
                    <div className='col-12 mt-3'>
                        <Typography className='text-center' variant='caption' component='h1'>
                            Create an account to continue
                        </Typography>
                    </div>
                </div>
                <div className='row justify-content-center mt-5'>
                    {orgShow ?
                        <div className='col-10'>
                            <div className="col-12 mt-3">
                                <Typography variant="subtitle1">{reqData.isOrganization ? t('login.fullName') : t('login.fullName')}:</Typography>
                                <TextField
                                    className="col-12 mt-2"
                                    variant="filled"
                                    type="email"
                                    placeholder={reqData.isOrganization ? t('login.orgName') : t('login.fullName')}
                                    onChange={(event) => {
                                        if (reqData.isOrganization) {
                                            setReqData({
                                                ...reqData,
                                                organizationName: event.target.value,
                                                fullName: event.target.value
                                            })
                                        } else {
                                            setReqData({
                                                ...reqData,
                                                fullName: event.target.value,
                                                organizationName: event.target.value,
                                            })
                                        }

                                    }
                                    }
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <Typography variant="subtitle1">{t('login.email')}:</Typography>
                                <TextField
                                    className="col-12 mt-2"
                                    variant="filled"
                                    type="email"
                                    placeholder={t('login.emailPlaceholder')}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        email: event.target.value
                                    })}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <div className="row mx-0 col-12 px-0">
                                    <div className="col-6 px-0">
                                        <Typography variant="subtitle1">{t('login.password')}:</Typography>
                                    </div>
                                    <div className="col-6 text-end px-0">
                                        <Typography className='col-12' variant='caption' component='h6'>
                                            <a href='/forget-password' style={{color: '#0D0D10'}}>Forget
                                                Password?</a>
                                        </Typography>
                                    </div>
                                </div>
                                <TextField
                                    className="col-12 mt-2"
                                    variant="filled"
                                    placeholder={t('login.passwordPlaceholder')}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        password: event.target.value
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleShowPassword}>
                                                    {showPassword ? <MdVisibilityOff/> : <MdVisibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <FormControlLabel onChange={(e, val) => setAcceptTerm(val)}
                                                  control={<Checkbox icon={<FaRegSquareFull size={18}/>}
                                                                     checkedIcon={<FiCheck size={18}/>}/>}
                                                  label="I accept terms and conditions"/>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="col-12 mt-3 px-0 px-lg-4">
                                    <Button className="col-12" variant="contained" color="primary"
                                            disabled={!acceptTerm}
                                            onClick={handleRegister}
                                    >
                                        {t('login.SignUp')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-10'>
                            <div className="col-12 text-center mt-3">
                                <Typography className='col-12' variant='caption' component='h6'>
                                    Choose your Register Type
                                </Typography>
                            </div>
                            <div className="col-12 mt-3">
                                <Button className="col-12"
                                        variant="text"
                                        onClick={() => {
                                            setReqData({...reqData, isOrganization: true})
                                            setOrgShow(true)
                                        }}
                                >
                                    As Organization
                                </Button>
                                {/*<Button className="col-12 mt-3"*/}
                                {/*        onClick={() => {*/}
                                {/*            setReqData({...reqData, isOrganization: false})*/}
                                {/*            setOrgShow(true)*/}
                                {/*        }}*/}
                                {/*>*/}
                                {/*    As individual*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                    }
                </div>
                <div className='col-12 text-center mt-3'>
                    <Typography className='col-12 text-center' variant='caption' component='h6'>
                        Already have an account?
                        <a href='/login' style={{color: '#49C12C'}}> Login</a>
                    </Typography>
                </div>
                <div className='row mx-0 col-12 text-center mt-5'>
                    <div className="col-3 align-content-center">Or login with</div>
                    <div className="col-3 text-center"><Button className="bg-unset" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginFacebook}>Facebook</Button></div>
                    <div className="col-3 text-center"><Button className="bg-unset" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginLinkedin}>Linkedin</Button></div>
                    <div className="col-3 text-center"><Button className="bg-unset" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginGoogle}>Google</Button></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 px-0 d-none d-lg-block slideimg h-100">
                <SimpleImageSlider
                    width='100%'
                    height='100%'
                    slideDuration={0.8}
                    images={[
                        loginslide1,
                        loginslide2,
                        loginslide3,
                        loginslide4,
                    ]}
                    autoPlay={true}
                    autoPlayDelay={20000}
                    showBullets={false}
                    showNavs={true}
                />
            </div>

        </div>
    )

}
