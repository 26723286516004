import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Input, Modal, TextField, Typography} from "@mui/material";
import {FcSupport} from "react-icons/fc";
import profile from "../../assets/profile.png";
import {useDispatch} from "react-redux";
import {getListOfCurrentUserOrganizationAPI, getOrganizationByIdAPI, updateOrganizationAPI} from "../../utils/redux/store/organizationSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {isNumber} from "lodash";
import {toast} from "react-toastify";

const UpdateOrganization = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const navigate = useNavigate()
    const entity: IOrganization = useAppSelector(state => state.organization.entity);
    const [reqData, setReqData] = useState({
        id: 0,
        orgName: '',
        description: '',
        avatar: '',
        location: '',
        contact_email: '',
        contact_phone: '',
        website_url: '',
        status: "active",
    })
    const [avatarMode, setAvatarMode] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setReqData({...reqData, avatar: reader.result});
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateOrganization = () => {
        if (!isNumber(Number(id))) {
            toast.warning("organization id invalid")
            return
        }
        dispatch(updateOrganizationAPI(reqData)).finally(() => navigate("/organization"));
    }

    useEffect(() => {
        dispatch(getListOfCurrentUserOrganizationAPI())
        dispatch(getOrganizationByIdAPI({id: Number(id)}))
    }, []);
    useEffect(() => {
        setReqData({
            id: entity.id!,
            orgName: entity.orgName!,
            description: entity.description!,
            avatar: entity.avatar!,
            location: entity.location!,
            contact_email: entity.contact_email!,
            contact_phone: entity.contact_phone!,
            website_url: entity.website_url!,
            status: "active",
        })
    }, [entity]);
    return (
        <Modal open={true}>
            <Box
                component="form"
                onSubmit={handleUpdateOrganization}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography className="row col-12 mx-0" variant="h5" component="h2" gutterBottom>
                    <div className="col-6 px-0">Update Organization</div>
                </Typography>

                <div className='col-12 text-center align-self-center mt-3 px-0'>
                    <Input
                        hidden
                        type='file'
                        id='profileInputId'
                        onChange={handleFileChange}
                    />
                    <label htmlFor='profileInputId'>
                        {avatarMode ?
                            <div className='position-relative' onMouseLeave={() => setAvatarMode(false)}
                                 onMouseEnter={() => setAvatarMode(true)}>
                                <Avatar className='avatarProfilePicHover' variant='circular' component='span'
                                        sx={{height: 88, width: 88}} src={reqData.avatar}>
                                    <FcSupport/>
                                </Avatar>
                                <div
                                    className='avatarProfilePicHoverDiv position-absolute align-self-center text-center top-0'>
                                    <img src={profile} style={{marginTop: '30px'}} alt="profile"/>
                                </div>
                            </div>
                            :
                            <Avatar className='avatarprofilepic' onMouseLeave={() => setAvatarMode(false)}
                                    onMouseEnter={() => setAvatarMode(true)} variant='circular' component='span'
                                    sx={{height: 88, width: 88}} src={reqData.avatar}
                            />
                        }
                    </label>
                </div>


                <TextField
                    fullWidth
                    label="organization Name"
                    name="orgName"
                    value={reqData.orgName}
                    onChange={(e) => setReqData({...reqData, orgName: e.target.value})}
                    margin="normal"
                    required
                />
                <TextField
                    fullWidth
                    label="description"
                    name="description"
                    value={reqData.description}
                    onChange={(e) => setReqData({...reqData, description: e.target.value})}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="location"
                    name="location"
                    value={reqData.location}
                    onChange={(e) => setReqData({...reqData, location: e.target.value})}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Email"
                    name="contact_email"
                    value={reqData.contact_email}
                    onChange={(e) => setReqData({...reqData, contact_email: e.target.value})}
                    margin="normal"
                    required
                />
                <TextField
                    fullWidth
                    label="Phone"
                    name="contact_phone"
                    value={reqData.contact_phone}
                    onChange={(e) => setReqData({...reqData, contact_phone: e.target.value})}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Website URL"
                    name="website_url"
                    value={reqData.website_url}
                    onChange={(e) => setReqData({...reqData, website_url: e.target.value})}
                    margin="normal"
                />
                <div className="d-flex gap-2 justify-content-end mt-3">
                    <Button component={Link} to="/organization" variant="outlined" color="warning">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleUpdateOrganization}>
                        Update
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default UpdateOrganization;
