import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createAsyncThunk, isFulfilled, isPending} from "@reduxjs/toolkit";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {IInviteeSubscription} from "../model/inviteeSubscription.model";
import {IOrganization} from "../model/organization.model";

const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/organizationInvitation';


export const getAllInvitationListAPI = createAsyncThunk(
    'organizationInvitation/fetch_AllInvitationList',
    async (orgId: string) => {
        const requestUrl = `${apiUrl}/getAllInvitationList?id=` + orgId;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const assignRoleToInviteeAPI = createAsyncThunk(
    'organizationInvitation/fetch_assignRoleToInviteeAPI',
    async (entity: { organizationInvitation_id: number, role: "basic" | "admin" | "manager" }) => {
        const requestUrl = `${apiUrl}/assignRoleToInvitee`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const deleteInviteeAPI = createAsyncThunk(
    'organizationInvitation/fetch_deleteInviteeAPI',
    async (entity: { id: number }) => {
        const requestUrl = `${apiUrl}/deleteInvitee`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const editInviteeEmailAPI = createAsyncThunk(
    'organizationInvitation/fetch_editInviteeEmailAPI',
    async (entity: { invitee_id: number, email: string, orgId: number }) => {
        const requestUrl = `${apiUrl}/editInvite`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const getUnAssignedUserSubscriptionByOrganizationIdAPI = createAsyncThunk(
    'organizationInvitation/fetch_getUnAssignedUserSubscriptionByOrganizationIdAPI',
    async (orgId: string) => {
        const requestUrl = `${apiUrl}/getUnAssignedUserSubscriptionByOrganizationId?id=` + orgId;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const inviteToOrganizationAPI = createAsyncThunk(
    'organizationInvitation/fetch_inviteToOrganizationAPI',
    async (entity: { invitee_email: string, orgId: number }) => {
        const requestUrl = `${apiUrl}/inviteToOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const ReAssignUserSubscriptionToInviteeIdAPI = createAsyncThunk(
    'organizationInvitation/fetch_ReAssignUserSubscriptionToInviteeIdAPI',
    async (entity: { inviteeId: number, userSubscriptionId: string }) => {
        const requestUrl = `${apiUrl}/ReAssignUserSubscriptionToInviteeId`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const revokeInviteeSubscriptionAPI = createAsyncThunk(
    'organizationInvitation/fetch_revokeInviteeSubscriptionAPI',
    async (entity: { invitee_id: number, orgId: number }) => {
        const requestUrl = `${apiUrl}/revokeInviteeSubscription?orgId=${entity.orgId}`;
        return axios.post<any>(requestUrl, cleanEntity({invitee_id: entity.invitee_id}));
    },
    {serializeError: serializeAxiosError}
)
export const subscribeToPlanBulkForOrgAPI = createAsyncThunk(
    'organizationInvitation/fetch_subscribeToPlanBulkForOrgAPI',
    async (entity: IInviteeSubscription[]) => {
        const requestUrl = `${apiUrl}/subscribeToPlanBulkForOrg`;
        return axios.post<any>(requestUrl, entity);
    },
    {serializeError: serializeAxiosError}
)
export const organizationInvitationSlice = createEntitySlice({
    name: 'organizationInvitation',
    initialState,

    extraReducers: (builder) => {
        builder
            // .addCase(getMostToolsUsed.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(getMostToolsUsed.fulfilled, (state, action) => {
            //     state.entity = action.payload.data;
            //     state.loading = false;
            // })
            // .addCase(getMostToolsUsed.rejected, (state) => {
            //     (state as any).isAuthenticated = false;
            //     state.loading = false;
            // })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.pending, (state) => {
                (state as any).loadingUnAssignedUserSubscription = true;
            })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.fulfilled, (state, action) => {
                (state as any).entityUnAssignedUserSubscription = action.payload.data;
                (state as any).loadingUnAssignedUserSubscription = false;
            })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.rejected, (state) => {
                (state as any).loadingUnAssignedUserSubscription = false;
            })
            .addMatcher(isFulfilled(getAllInvitationListAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getAllInvitationListAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = organizationInvitationSlice.actions;

export default organizationInvitationSlice.reducer;
