import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button, Card, Input, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack, IoInformationCircle} from "react-icons/io5";
import {convertLinuxDateToDate} from "../../utils/convertLinuxDateToDate";
import {useTranslation} from "react-i18next";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {createOrganizationAPI, getListOfCurrentUserOrganizationAPI} from "../../utils/redux/store/organizationSlice";
import {MdAdd} from "react-icons/md";
import dayjs from "dayjs";
import {FcSupport} from "react-icons/fc";
import profile from "../../assets/profile.png";
import {setOrganizationIconAPI} from "../../utils/redux/store/userSlice";
import {checkValidate} from "../../utils/redux/store/authSlice";

const OrganizationList = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const entities: IOrganization[] = useAppSelector(state => state.organization.entities);
    const [search, setSearch] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [avatarMode, setAvatarMode] = useState(false);
    const [reqData, setReqData] = useState({
        orgName: '',
        description: '',
        avatar: '',
        location: '',
        contact_email: 'null@null.null',
        contact_phone: '',
        website_url: ''
    })
    const [showCreateNewModal, setShowCreateNewModal] = useState(false)

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Convert expiration_date to Unix timestamp
        try {
            dispatch(createOrganizationAPI({...reqData,contact_email: 'null@null.null'})).finally(() => {
                dispatch(getListOfCurrentUserOrganizationAPI());
                setShowCreateNewModal(false);
            })

        } catch (error) {
            console.error("Failed to create organization", error);
        } finally {
            setReqData({
                orgName: '',
                description: '',
                avatar: '',
                location: '',
                contact_email: '',
                contact_phone: '',
                website_url: ''
            })
        }
    };
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setReqData({...reqData, avatar: reader.result});
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        dispatch(getListOfCurrentUserOrganizationAPI());
    }, [dispatch]);

    return (
        <div className="row mx-0">
            <Button
                component={Link} // Use component instead of LinkComponent
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>

            <Card variant="elevation" className="row mx-0 col-12 col-lg-12 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('organization.organizationList')}</Typography>
                </div>
                <div className="row mx-0 col-12 align-items-center">
                    <div className="col-12 col-lg-6">
                        <Typography variant="subtitle1">Search :</Typography>
                        <TextField
                            className="col-12 col-md-5 col-lg-5"
                            type="email"
                            placeholder="search by company name"
                            value={search}
                            onChange={event => setSearch(event.target.value)}
                        />
                    </div>
                    <div className="col-12 col-lg-6 text-end">
                        <Button variant="contained" startIcon={<MdAdd/>} onClick={() => setShowCreateNewModal(true)}>Create Organization</Button>
                        <Modal open={showCreateNewModal} onClose={() => {
                            setShowCreateNewModal(false)
                            setReqData({
                                orgName: '',
                                description: '',
                                avatar: '',
                                location: '',
                                contact_email: '',
                                contact_phone: '',
                                website_url: ''
                            })
                        }}>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography className="row col-12 mx-0" variant="h5" component="h2" gutterBottom>
                                    <div className="col-6 px-0">Create Organization</div>
                                </Typography>

                                <div className='col-12 text-center align-self-center mt-3 px-0'>
                                    <Input
                                        hidden
                                        type='file'
                                        id='profileInputId'
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor='profileInputId'>
                                        {avatarMode ?
                                            <div className='position-relative' onMouseLeave={() => setAvatarMode(false)}
                                                 onMouseEnter={() => setAvatarMode(true)}>
                                                <Avatar className='avatarProfilePicHover' variant='circular' component='span'
                                                        sx={{height: 88, width: 88}} src={reqData.avatar}>
                                                    <FcSupport/>
                                                </Avatar>
                                                <div
                                                    className='avatarProfilePicHoverDiv position-absolute align-self-center text-center top-0'>
                                                    <img src={profile} style={{marginTop: '30px'}} alt="profile"/>
                                                </div>
                                            </div>
                                            :
                                            <Avatar className='avatarprofilepic' onMouseLeave={() => setAvatarMode(false)}
                                                    onMouseEnter={() => setAvatarMode(true)} variant='circular' component='span'
                                                    sx={{height: 88, width: 88}} src={reqData.avatar}
                                            />
                                        }
                                    </label>
                                </div>


                                <TextField
                                    fullWidth
                                    label="organization Name"
                                    name="orgName"
                                    value={reqData.orgName}
                                    onChange={(e) => setReqData({...reqData, orgName: e.target.value})}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="description"
                                    name="description"
                                    value={reqData.description}
                                    onChange={(e) => setReqData({...reqData, description: e.target.value})}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="location"
                                    name="location"
                                    value={reqData.location}
                                    onChange={(e) => setReqData({...reqData, location: e.target.value})}
                                    margin="normal"
                                />
                                {/*<TextField*/}
                                {/*    fullWidth*/}
                                {/*    label="Email"*/}
                                {/*    name="contact_email"*/}
                                {/*    value={reqData.contact_email}*/}
                                {/*    onChange={(e) => setReqData({...reqData, contact_email: e.target.value})}*/}
                                {/*    margin="normal"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="contact_phone"
                                    value={reqData.contact_phone}
                                    onChange={(e) => setReqData({...reqData, contact_phone: e.target.value})}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Website URL"
                                    name="website_url"
                                    value={reqData.website_url}
                                    onChange={(e) => setReqData({...reqData, website_url: e.target.value})}
                                    margin="normal"
                                />
                                <Button type="submit" variant="contained" color="primary" sx={{mt: 2}}>
                                    Create Organization
                                </Button>
                            </Box>
                        </Modal>
                    </div>
                </div>
                {entities?.length > 0 ? (
                    <TableContainer className="mt-3" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="fw-bold">ID</TableCell>
                                    <TableCell className="fw-bold">Logo</TableCell>
                                    <TableCell className="fw-bold">Organization Name</TableCell>
                                    <TableCell className="fw-bold">Status</TableCell>
                                    <TableCell className="fw-bold">Contact phone</TableCell>
                                    <TableCell className="fw-bold">Description</TableCell>
                                    <TableCell className="fw-bold">Location</TableCell>
                                    <TableCell className="fw-bold">Website url</TableCell>
                                    <TableCell className="fw-bold">Created Date</TableCell>
                                    <TableCell className="fw-bold text-center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entities
                                    .filter((y) => search === "" || (y.orgName && y.orgName.includes(search)))
                                    .map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell><img src={row.avatar!} width={50}/></TableCell>
                                            <TableCell>{row.orgName}</TableCell>
                                            <TableCell
                                                className={row.status?.toLowerCase() === "active"
                                                    ? "text-success fw-bold"
                                                    : "text-danger fw-bold"
                                                }
                                            >
                                                {row.status ?? 'Unknown'}
                                            </TableCell>
                                            <TableCell>{row.contact_phone}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>{row.location}</TableCell>
                                            <TableCell>{row.website_url}</TableCell>
                                            <TableCell>{convertLinuxDateToDate(row.createdDate!)}</TableCell>
                                            <TableCell className="d-flex text-center gap-1 align-items-center" component="th" scope="row">
                                                <Tooltip title="Invite user">
                                                    <Button component={Link} to={"/organization/manage/"+row.id} variant="outlined" color="info">
                                                        manage
                                                    </Button>
                                                </Tooltip>
                                                <Link to={"/organization/DeleteOrganization/" + row.id}>
                                                    <Tooltip title="Delete">
                                                        <Button variant="outlined" color="error" onClick={() => setShowDeleteModal(true)}>
                                                            Delete
                                                        </Button>
                                                    </Tooltip>
                                                </Link>
                                                <Link to={"/organization/UpdateOrganization/" + row.id}>
                                                    <Tooltip title="Edit">
                                                        <Button variant="contained" color="error" onClick={() => setShowUpdateModal(true)}>
                                                            Edit
                                                        </Button>
                                                    </Tooltip>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h5" className="mt-3">{t("organization.noOrganizationFound")}</Typography>
                )}
            </Card>
        </div>
    );
};

export default OrganizationList;
